import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import routes from "../routes/route.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Provider from "../utils/AppProvider";
import { useState } from "react";
import NotFound from "../common/NotFound";
import { logout } from "../services/AuthService";
import { toast } from "react-toastify";
import ConfirmationPopupModal from "../components/ConfirmationPopupModal";

let is_authenticated = "";
export let userId;

const Index = () => {
  const [flag, setFlag] = useState(0);
  const [signOutPopup, setsignOutPopup] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(async () => {
    is_authenticated = await localStorage.getItem("is_authenticated");
    userId = localStorage.getItem("userId");

    let auth_url = [
      "/login",
      "/register",
      "/my-account/lost-password",
      "/create-account/:token",
      "/my-account/reset-password",
    ];
    let resetPassLink =
      location?.pathname?.split("/")[1] === "my-account" &&
      location?.pathname?.split("/")[2] === "reset-password";
    if (
      location?.pathname?.split("/")[1] === "create-account" ||
      resetPassLink
    ) {
      // navigate(`/create-account/${location?.pathname?.split("/")[2]}`);
    } else {
      if (
        (is_authenticated == 0 ||
          is_authenticated == null ||
          is_authenticated == undefined) &&
        auth_url.includes(location.pathname)
      ) {
        navigate(location.pathname);
        return true;
      } else if (
        (is_authenticated == 0 ||
          is_authenticated == null ||
          is_authenticated == undefined) &&
        !auth_url.includes(location.pathname)
      ) {
        navigate("/login");
        return true;
      } else if (
        is_authenticated == 1 &&
        auth_url.includes(location.pathname)
      ) {
        navigate("/");
      }
    }
    return () => {};
  }, [location.pathname]);

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout()
      .then(() => {
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("opening_balance");
        localStorage.removeItem("adjustment_balance");
        //   setIsAuthenticated(0);
        setsignOutPopup(false);
        navigate("/login");
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <>
      <Provider>
        <Header
          signOutClicked={() => {
            setsignOutPopup(true);
          }}
        />
        <ConfirmationPopupModal
          title={"Confirmation Popup"}
          content={`Are you Sure you want to Sign out?`}
          show={signOutPopup}
          setShow={setsignOutPopup}
          confirmClicked={(e) => {
            handleLogout(e);
          }}
        />

        <Routes>
          {routes.map((route, i) => {
            if (location.pathname == route.path && route.private === true) {
              if (localStorage.getItem("is_authenticated")) {
                return (
                  <Route
                    exact
                    path={route.path}
                    key={i}
                    element={<route.page.component />}
                  >
                    {" "}
                  </Route>
                );
              }
            } else {
              return (
                <Route
                  exact
                  path={route.path}
                  key={i}
                  element={<route.page.component />}
                >
                  {" "}
                </Route>
              );
            }
          })}
          <Route path="*" element={<NotFound />}>
            {" "}
          </Route>
        </Routes>
        <Footer></Footer>
      </Provider>
    </>
  );
};

export default Index;
