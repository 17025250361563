import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../utils/AppProvider";

const FooterMiddle = () => {
  const { is_authenticated } = useContext(AppContext);

  return (
    <section className="section-padding footer-mid">
      <div className="container pt-15">
        <div className="row">
          <div className="col">
            <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0">
              <div className="logo mb-30">
                <Link to="/" className="mb-15">
                  <img src="/images/sl_logo.png" alt="logo" />
                </Link>
                <p className="font-lg text-heading">Silverline Wholesale</p>
              </div>
              <ul className="contact-infor">
                <li className="d-flex">
                  <div className="d-flex ">
                    <img
                      src="/images/theme/icons/icon-location.svg"
                      alt=""
                      style={{ paddingBottom: "44px" }}
                    />
                    <strong style={{ textAlign: "center" }}>Address: </strong>{" "}
                  </div>
                  <div className="mr-2">
                    1442 Norwood Ave,
                    <br />
                    Itasca , IL 60143
                    <br />
                    United States
                  </div>
                </li>
                <li>
                  <img src="/images/theme/icons/icon-contact.svg" alt="" />
                  <strong>Call Us: </strong>
                  <span>(630) 785-6838</span>
                </li>
                <li>
                  <img src="/images/theme/icons/icon-email-2.svg" alt="" />
                  <strong>Email: </strong>
                  <span>info@silverlinewholesale.com</span>
                </li>
                <li>
                  <img src="/images/theme/icons/icon-clock.svg" alt="" />
                  <strong>Hours: </strong>
                  <span>09:00 - 18:00, Mon - Fri</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-link-widget col-xl col-6">
            <h4 className="widget-title">Company</h4>
            <ul className="footer-list mb-sm-5 mb-md-0">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>

              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="footer-link-widget col-xl col-6">
            <h4 className="widget-title">Account</h4>
            <ul className="footer-list mb-sm-5 mb-md-0">
              {is_authenticated ? (
                <li>
                  <Link to="/my-account">My Account</Link>
                </li>
              ) : (
                <li>
                  <Link to="/login">Sign In</Link>
                </li>
              )}
              <li>
                <Link to="/cart">View Cart</Link>
              </li>
              <li>
                <Link to="/wishlist">My Wishlist</Link>
              </li>

              <li>
                <Link to="/my-account/addresses">Shipping Details</Link>
              </li>
            </ul>
          </div>
          {/* <div className="footer-link-widget col">
                            <h4 className="widget-title">Corporate</h4>
                            <ul className="footer-list mb-sm-5 mb-md-0">
                                <li>
                                    <a href="#">Become a Vendor</a>
                                </li>
                                <li>
                                    <a href="#">Affiliate Program</a>
                                </li>
                                <li>
                                    <a href="#">Farm Business</a>
                                </li>
                                <li>
                                    <a href="#">Farm Careers</a>
                                </li>
                                <li>
                                    <a href="#">Our Suppliers</a>
                                </li>
                                <li>
                                    <a href="#">Accessibility</a>
                                </li>
                                <li>
                                    <a href="#">Promotions</a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-link-widget col">
                            <h4 className="widget-title">Popular</h4>
                            <ul className="footer-list mb-sm-5 mb-md-0">
                                <li>
                                    <a href="#">Milk &amp; Flavoured Milk</a>
                                </li>
                                <li>
                                    <a href="#">Butter and Margarine</a>
                                </li>
                                <li>
                                    <a href="#">Eggs Substitutes</a>
                                </li>
                                <li>
                                    <a href="#">Marmalades</a>
                                </li>
                                <li>
                                    <a href="#">Sour Cream and Dips</a>
                                </li>
                                <li>
                                    <a href="#">Tea &amp; Kombucha</a>
                                </li>
                                <li>
                                    <a href="#">Cheese</a>
                                </li>
                            </ul>
                        </div> */}
          <div className="footer-link-widget widget-install-app col">
            {/* <h4 className="widget-title">Install App</h4> */}
            {/* <p className="wow fadeIn animated">From App Store or Google Play</p> */}
            {/* <div className="download-app">
                            <a href="https://itunes.apple.com/us/app/silverline-wholesale/id1102703637?mt=8" className="hover-up mb-sm-2 mb-lg-0">
                                <img
                                    className="active"
                                    src="/images/theme/app-store.jpg"
                                    alt=""
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.silverline" className="hover-up mb-sm-2">
                                <img src="/images/theme/google-play.jpg" alt="" />
                            </a>
                        </div> */}
            <p className="mb-20">Secured Payment Gateways</p>
            <img
              className="wow fadeIn animated"
              src="/images/theme/payment-method.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterMiddle;
