import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveCartAndCustomer = async (stateId) => {
  let token = localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/cart-customer?stateId=${stateId || 0}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const createOrder = async (order) => {
  let token = localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/create-order", { ...order }, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveOrders = async (id) => {
  let token = localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/retrive-orders?stateId=${id}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveOrdersDetails = async (id, stateId) => {
  let token = localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/retrive-one-orders?id=${id}&stateId=${stateId}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

function handleAuthFailure(error, reject) {
  if (
    error?.response.status === 401 &&
    error?.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("is_authenticated");

    if (window.location.pathname != "/login") {
      window.location.href = "/login";
    }
  } else {
    reject(error);
  }
}

export {
  retriveCartAndCustomer,
  createOrder,
  retriveOrdersDetails,
  retriveOrders,
};
