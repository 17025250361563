import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { sendContactMessage } from "../../services/ContactService";

const Contact = () => {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      await sendContactMessage(contact)
        .then((response) => {
          toast("Message Sent Successfully", { type: "success" });

          setContact({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error", error.response);
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    if (contact.name == "") {
      toast("Please enter name.", {
        type: "error",
      });
      return false;
    }
    if (contact.email == "") {
      toast("Please enter email.", {
        type: "error",
      });
      return false;
    }
    if (contact.subject == "") {
      toast("Please enter subject.", {
        type: "error",
      });
      return false;
    }
    if (contact.message == "") {
      toast("Please enter subject.", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Contact
          </div>
        </div>
      </div>
      <div className="page-content pt-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <section className="mb-50">
                <div className="row mb-60 contact-page">
                  <div className="col-md-4 mb-4 mb-md-0">
                    <h4 className="mb-15 text-brand">
                      {" "}
                      <i className="fi-rs-marker mr-5" /> Address
                    </h4>
                    1442 Norwood Ave Itasca <br />
                    IL 60143, <br />
                    United States
                  </div>
                  <div className="col-md-4 mb-4 mb-md-0">
                    <h4 className="mb-15 text-brand">
                      <i className="fi-rs-smartphone mr-5" />
                      Phone{" "}
                    </h4>
                    Ph: (630) 785-6838 <br />
                    Toll free : (855) LIKE-SWI (545-3794) <br />
                    Fax : (630) 785-6845
                  </div>
                  <div className="col-md-4">
                    <h4 className="mb-15 text-brand">
                      <i className="fi-rs-envelope mr-5" />
                      Email
                    </h4>
                    info@silverlinewholesale.com
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-8">
                    <div className="contact-from-area padding-20-row-col">
                      <h5 className="text-brand mb-10">Contact form</h5>
                      <h2 className="mb-10">Drop Us a Line</h2>
                      <p className="text-muted mb-30 font-sm">
                        Your email address will not be published. Required
                        fields are marked *
                      </p>
                      <form
                        className="contact-form-style mt-30"
                        id="contact-form"
                        action="#"
                        method="post"
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input
                                name="name"
                                placeholder="Your Name *"
                                type="text"
                                value={contact.name}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input
                                name="email"
                                placeholder="Your Email *"
                                type="email"
                                value={contact.email}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <div className="input-style mb-20">
                              <input
                                name="subject"
                                placeholder="Subject *"
                                type="text"
                                value={contact.subject}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="textarea-style mb-30">
                              <textarea
                                name="message"
                                placeholder="Message *"
                                rows={10}
                                value={contact.message}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                            <button
                              className="submit submit-auto-width"
                              type="submit"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Send message
                            </button>
                          </div>
                        </div>
                      </form>
                      <p className="form-messege" />
                    </div>
                  </div>
                  <div className="col-lg-4 pl-50 d-lg-block d-none">
                    <img
                      className="border-radius-15 mt-50"
                      src="/images/page/contact-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Contact;
