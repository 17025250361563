import { Link } from "react-router-dom";

const Banner = () => {
  let ele = document?.getElementById("noright_click");
  ele?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });

  return (
    <section className="banners mb-25">
      <div className="container">
        <div className="row" id="noright_click">
          <div className="col-lg-4 col-md-6">
            <div className="banner-img">
              <img src="/images/banner/secondRow3.jpg" alt="" />
              <div className="banner-text">
                <h4>
                  {/* Everyday Fresh &amp; <br />
                    Clean with Our
                    <br />
                    Products */}
                </h4>
                <Link
                  to="/product-category/silicon-pipe"
                  className="btn btn-xs"
                >
                  Shop Now <i className="fi-rs-arrow-small-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="banner-img">
              <img src="/images/banner/secondRow1.jpg" alt="" />
              <div className="banner-text">
                <h4>
                  {/* Make your Breakfast
                    <br />
                    Healthy and Easy */}
                </h4>
                <Link
                  to="/product-category/cigarette-case"
                  className="btn btn-xs"
                >
                  Shop Now <i className="fi-rs-arrow-small-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="banner-img mb-sm-0">
              <img src="/images/banner/secondRow2.jpg" alt="" />
              <div className="banner-text">
                <h4>
                  {/* The best Organic <br />
                    Products Online */}
                </h4>
                <Link
                  to={"/product-category/glass-pipe"}
                  className="btn btn-xs"
                >
                  Shop Now <i className="fi-rs-arrow-small-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
