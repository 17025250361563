import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { register } from "../../services/AuthService";
import { getPOSStates } from "../../services/StateService";
import { getAllCountry } from "../../services/CountryService";
import { AppContext } from "../../utils/AppProvider";
import { randomSecurityCode } from "../../utils/constant";
import ConfirmationPopupModal from "../ConfirmationPopupModal";
// import { getCustomerCategories,getPosShipVias,getPosPaymentTypes } from "../../services/CustomerService";

const Register = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [passwordType, setpasswordType] = useState(false);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [isAgree, setIsAgree] = useState(false);
  const [confirmationPopup, setconfirmationPopup] = useState(false);
  const [billingAddressCheckbox, setbillingAddressCheckbox] = useState(false);
  // const [shipVia, setShipVia] = useState([]);
  // const [paymentType, setPaymentType] = useState([]);
  // const [customerCategory, setCustomerCategory] = useState([]);

  const [customer, setCustomer] = useState({
    name_prefix: "Mr.",
    name: "",
    email: "",
    alt_email: "",
    username: "",
    company_name: "",
    address: "",
    pincode: "",
    city: "",
    country_id: "2",
    state_id: "",
    tax_id: "",
    tax_expiry_date: "",
    tax_certificate: "",
    password: "",
    confirm_password: "",
    mobile: "",
    phone: "",
    dba: "",
    manager_number: "",
    fax: "",
    tob_license_no: "",
    tob_expiry_date: "",
    tob_certificate: "",
    code: "",
    // billing_street: "",
    billing_city: "",
    billing_address: "",
    billing_state: "",
    billing_zip: "",
  });
  const usernameRegex = /^[a-zA-Z0-9]*$/;

  const { setIsAuthenticated } = useContext(AppContext);
  let date = new Date();
  date =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getCountry();

    // getPosShipVia();
    // getPosPaymentType();
    // getCustomerCategory();
    setSecurityCode(randomSecurityCode());
  }, []);

  const getPOSState = async (id) => {
    if (id != "") {
      await getPOSStates(id)
        .then((response) => {
          setStates(response.data.data);
        })
        .catch((error) => {});
    } else {
      setStates([]);
    }
  };
  // const getPosShipVia = async () => {
  //     await getPosShipVias().then((response) => {
  //         setShipVia(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  // const getPosPaymentType = async () => {
  //     await getPosPaymentTypes().then((response) => {
  //         setPaymentType(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  // const getCustomerCategory = async () => {
  //     await getCustomerCategories().then((response) => {
  //         setCustomerCategory(response.data.data);
  //     }).catch((error) => {
  //         console.log("Error:", error);
  //     });
  // }
  const getCountry = async () => {
    await getAllCountry()
      .then((response) => {
        setCountry(response.data);
        getPOSState(customer?.country_id);
      })
      .catch((error) => {});
  };

  const handleInputChange = async (e) => {
    let { name, value } = e.target;
    if (name == "country_id" && customer.state_id != value) {
      // getPOSState(value);
    }

    if (
      name == "phone" ||
      name == "mobile" ||
      name == "manager_number" ||
      name == "fax"
    ) {
      if (value != null) {
        var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      }
    }
    setCustomer({ ...customer, [name]: value });

    if (name == "tax_certificate" || name == "tob_certificate") {
      setCustomer({ ...customer, [name]: e.target.files[0] });
      return;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      let mobilenumber = customer.mobile.replace(/\D/g, "");
      let phonenumber = customer.phone?.replace(/\D/g, "");
      let manager_number = customer.manager_number?.replace(/\D/g, "");
      var fax = customer.fax?.replace(/\D/g, "");
      let formdata = new FormData();
      formdata.append("name_prefix", customer.name_prefix);
      formdata.append("name", customer.name);
      formdata.append("email", customer.email);
      formdata.append("alt_email", customer.alt_email);
      formdata.append("username", customer.username);
      formdata.append("company_name", customer.company_name);
      formdata.append("address", customer.address);
      formdata.append("pincode", customer.pincode);
      formdata.append("city", customer.city);
      formdata.append("country_id", customer.country_id);
      formdata.append("state_id", customer.state_id);
      formdata.append("tax_id", customer.tax_id);
      formdata.append("tax_expiry_date", customer.tax_expiry_date);
      formdata.append("tax_certificate", customer.tax_certificate);
      formdata.append("password", customer.password);
      formdata.append("mobile", mobilenumber);
      formdata.append("phone", phonenumber);
      formdata.append("manager_number", manager_number);
      formdata.append("dba", customer.dba);
      formdata.append("tob_license_no", customer.tob_license_no);
      formdata.append("tob_expiry_date", customer.tob_expiry_date);
      formdata.append("tob_certificate", customer.tob_certificate);
      // formdata.append("billing_street", customer.billing_street);
      formdata.append("billing_city", customer.billing_city);
      formdata.append("billing_address", customer.billing_address);
      formdata.append("billing_state", customer.billing_state);
      formdata.append("billing_zip", customer.billing_zip);

      // formdata.append('payment_type',customer.payment_type);
      // formdata.append('ship_via',customer.ship_via);
      // formdata.append('cc_tax_id',customer.cc_tax_id);
      formdata.append("fax", fax);

      await register(formdata)
        .then((response) => {
          toast("Customer Registered Successfully", { type: "success" });
          setconfirmationPopup(true);
        })
        .catch((error) => {
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    if (customer.name == "") {
      toast("Please enter name.", {
        type: "error",
      });
      return false;
    }
    if (customer.email == "") {
      toast("Please enter email.", {
        type: "error",
      });
      return false;
    }
    // if (customer.alt_email == "") {
    //   toast("Please enter Second email.", {
    //     type: "error",
    //   });
    //   return false;
    // }

    if (customer.username == "") {
      toast("Please enter username.", {
        type: "error",
      });
      return false;
    } else if (!usernameRegex.test(customer.username)) {
      toast(
        "Username should not be contain any speacial character or any space.",
        {
          type: "error",
        }
      );
      return false;
    } else if (
      customer?.username?.trim()?.length < 6 ||
      customer?.username?.trim()?.length > 12
    ) {
      toast("Username Must be between 6 to 12 characters long.", {
        type: "error",
      });
      return false;
    }

    if (customer.company_name == "") {
      toast("Please enter company name.", {
        type: "error",
      });
      return false;
    }
    if (customer.password == "") {
      toast("Please enter password.", {
        type: "error",
      });
      return false;
    }
    if (customer.confirm_password == "") {
      toast("Please enter confirm password.", {
        type: "error",
      });
      return false;
    }

    if (customer.confirm_password !== customer.password) {
      toast("Password and confirm password does not match.", {
        type: "error",
      });
      return false;
    }

    if (customer.address == "") {
      toast("Please enter addess.", {
        type: "error",
      });
      return false;
    }

    if (customer.pincode == "") {
      toast("Please enter Zip.", {
        type: "error",
      });
      return false;
    }

    if (customer.mobile == "") {
      toast("Please enter Primary contact.", {
        type: "error",
      });
      return false;
    }

    if (customer.country_id == "") {
      toast("Please select Country.", {
        type: "error",
      });
      return false;
    }

    if (customer.state_id == "") {
      toast("Please select states.", {
        type: "error",
      });
      return false;
    }

    if (customer.city == "") {
      toast("Please enter city.", {
        type: "error",
      });
      return false;
    }

    // if (customer.billing_street == "") {
    //   toast("Please enter Billing street.", {
    //     type: "error",
    //   });
    //   return false;
    // }
    if (customer.billing_city == "") {
      toast("Please enter Billing city.", {
        type: "error",
      });
      return false;
    }
    if (customer.billing_address == "") {
      toast("Please enter Billing address.", {
        type: "error",
      });
      return false;
    }
    if (customer.billing_state == "") {
      toast("Please enter Billing state.", {
        type: "error",
      });
      return false;
    }
    if (customer.billing_zip == "") {
      toast("Please enter Billing zip.", {
        type: "error",
      });
      return false;
    }

    if (customer.tax_id != "" || customer.tax_expiry_date != "") {
      if (customer.tax_id != "" && customer.tax_expiry_date != "") {
      } else {
        // if (customer.tax_certificate == "") {
        //   toast("Please Select Tax certificate.", {
        //     type: "error",
        //   });
        //   return false;
        // }
      }
    }
    // if(customer.payment_type == ''){
    //     toast("Please select payment type.",{
    //         type:'error'
    //     });
    //     return false
    // }
    // if(customer.cc_tax_id == ''){
    //     toast("Please select customer category.",{
    //         type:'error'
    //     });
    //     return false
    // }
    if (!isAgree) {
      toast("Please accept the terms and conditions.", {
        type: "error",
      });
      return false;
    }

    if (customer.code != securityCode) {
      toast("Security code does not match", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  const billingAddressToggle = (value) => {
    if (value) {
      setbillingAddressCheckbox(true);
      setCustomer({
        ...customer,
        billing_city: customer?.city,
        billing_address: customer?.address,
        billing_state: customer?.state_id,
        billing_zip: customer?.pincode,
      });
    } else {
      setbillingAddressCheckbox(false);
      setCustomer({
        ...customer,
        billing_city: "",
        billing_address: "",
        billing_state: "",
        billing_zip: "",
      });
    }
  };

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Registration
          </div>
        </div>
      </div>
      <div className="page-content register-page pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <form method="post">
                <div className="row">
                  <div className="heading_s1">
                    <h1 className="mb-5">Create an Account</h1>
                    <p className="mb-30 login-hover">
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                  <div className="login_wrap widget-taber-content background-white">
                    <p>Note: please fill in the * required information</p>
                    <div className="padding_eight_all w-100 bg-white d-lg-inline-flex">
                      <div className="col-xl-6 m-2">
                        {/* <div className="form-group">
                          <div className="row d-flex"> */}
                        {/* <div className="col-xl-3 col-3">
                              <select
                                className="form-select"
                                name="name_prefix"
                                value={customer.name_prefix}
                                onChange={(e) => handleInputChange(e)}
                              >
                                <option value="Mr.">Mr.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                                <option value="Miss.">Miss.</option>
                              </select>
                            </div> */}
                        {/* </div>
                        </div> */}
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="name"
                            value={customer.name}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Name *"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="email"
                            value={customer.email}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Email *"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="company_name"
                            value={customer.company_name}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Company Name *"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="fax"
                            id="fax"
                            value={customer.fax}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Fax Number"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="dba"
                            value={customer.dba}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Doing Business As"
                          />
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            name="country_id"
                            value={customer.country_id}
                            disabled={true}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Select Shipping country *</option>
                            {country.map((country, index) => {
                              return (
                                <option key={index} value={country.id}>
                                  {" "}
                                  {country.country}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            name="state_id"
                            value={customer.state_id}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Select Shipping state *</option>
                            {states.map((state, index) => {
                              return (
                                <option key={index} value={state.id}>
                                  {" "}
                                  {state.state}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="city"
                            value={customer.city}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Shipping city*"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            style={{ minHeight: "64px" }}
                            required=""
                            name="address"
                            rows={1}
                            value={customer.address}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Shipping address *"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            required=""
                            name="pincode"
                            value={customer.pincode}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Shipping zip *"
                          />
                        </div>
                        <div
                          className="login_footer form-group  "
                          style={{ marginBottom: "7px" }}
                        >
                          <div className="chek-form">
                            <div className="custome-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={billingAddressCheckbox}
                                id="billingAddressCheckbox"
                                onChange={(e) =>
                                  billingAddressToggle(e.target.checked)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="billingAddressCheckbox"
                              >
                                <span>
                                  Billing address is same as Shipping address
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            name="country_id"
                            value={customer.country_id}
                            disabled={true}
                            // onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Select Billing country *</option>
                            {country.map((country, index) => {
                              return (
                                <option key={index} value={country.id}>
                                  {" "}
                                  {country.country}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            name="billing_state"
                            value={customer.billing_state}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Select Billing State *</option>
                            {states.map((state, index) => {
                              return (
                                <option key={index} value={state.id}>
                                  {" "}
                                  {state.state}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="billing_city"
                            value={customer.billing_city}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Billing city *"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            style={{ minHeight: "64px" }}
                            required=""
                            name="billing_address"
                            rows={1}
                            value={customer.billing_address}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Billing address *"
                          />
                        </div>
                        {/* <div className="form-group">
                          <input
                            name="billing_street"
                            value={customer.billing_street}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Billing street "
                          />
                        </div> */}
                        <div className="form-group">
                          <input
                            type="number"
                            required=""
                            name="billing_zip"
                            value={customer.billing_zip}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Billing zip *"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-2">
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="username"
                            value={customer.username}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Username *"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="alt_email"
                            value={customer.alt_email}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Second email "
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="mobile"
                            id="mobile"
                            value={customer.mobile}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Primary Contact#"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="phone"
                            id="phone"
                            value={customer.phone}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Secondary Contact#"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            required=""
                            type={passwordType ? "text" : "password"}
                            name="password"
                            value={customer.password}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Password *"
                          />
                          <img
                            onClick={() => setpasswordType(!passwordType)}
                            src={
                              "/images/theme/icons/" +
                              (passwordType
                                ? "icon-eye.svg"
                                : "icon-eye-off.svg")
                            }
                            className="show-password"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            required=""
                            type={passwordType ? "text" : "password"}
                            name="confirm_password"
                            value={customer.confirm_password}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Confirm password *"
                          />
                          <img
                            onClick={() => setpasswordType(!passwordType)}
                            src={
                              "/images/theme/icons/" +
                              (passwordType
                                ? "icon-eye.svg"
                                : "icon-eye-off.svg")
                            }
                            className="show-password"
                            alt="show-password"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="manager_number"
                            id="manager_number"
                            value={customer.manager_number}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Manager Number#"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="tax_id"
                            value={customer.tax_id}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Tax ID"
                          />
                        </div>
                        <label>Tax Expiry Date:</label>
                        <div className="form-group">
                          <input
                            type="date"
                            required=""
                            name="tax_expiry_date"
                            min={date}
                            value={customer.tax_expiry_date}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>
                        <label>Tax Certificate:</label>
                        <div className="form-group">
                          <input
                            type="file"
                            required=""
                            className="form-control form-control-lg"
                            name="tax_certificate"
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Tax Certificate"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="tob_license_no"
                            value={customer.tob_license_no}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Tob License No"
                          />
                        </div>
                        <label>Tobacco Expiry Date:</label>
                        <div className="form-group">
                          <input
                            type="date"
                            required=""
                            name="tob_expiry_date"
                            min={date}
                            value={customer.tob_expiry_date}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>
                        <label>Tobacco Certificate:</label>
                        <div className="form-group">
                          <input
                            type="file"
                            required=""
                            className="form-control form-control-lg"
                            name="tob_certificate"
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Tobacco Certificate"
                          />
                        </div>
                        {/* <div className="form-group">
                                            <select name="ship_via" id="ship_via" value={customer.ship_via} onChange={(e) => handleInputChange(e)} className="form-control">
                                                <option value="0">Select Ship Via</option>
                                                {
                                                    shipVia.map((shipVia,index) =>{
                                                        return (
                                                            <option key={index} value={shipVia.id}> {shipVia.ship_type}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            </div>
                                            <div className="form-group">
                                            <select name="payment_type" id="payment_type" value={customer.payment_type} onChange={(e) => handleInputChange(e)} className="form-control">
                                            <option value="0">Select Payment Type *</option>
                                                {
                                                    paymentType.map((payment,index) =>{
                                                        return (
                                                            <option key={index} value={payment.id}> {payment.payment_type}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            </div>
                                            <div className="form-group">
                                            <select name="cc_tax_id" id="cc_tax_id" value={customer.cc_tax_id} onChange={(e) => handleInputChange(e)} className="form-control">
                                            <option value="0">Select Membership Type *</option>
                                                {
                                                    customerCategory.map((cc,index) =>{
                                                        return (
                                                            <option key={index} value={cc.cc_id}> {cc.cc_name}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            </div> */}
                        <div className="login_footer form-group">
                          <div className="chek-form">
                            <input
                              type="text"
                              required=""
                              name="code"
                              value={customer.code}
                              onChange={(e) => handleInputChange(e)}
                              placeholder="Security code *"
                            />
                          </div>
                          <span className="security-code">
                            {securityCode.split("").map((num, index) => {
                              return index == 0 ? (
                                <b key={index} className="text-new">
                                  {num}
                                </b>
                              ) : index == 1 ? (
                                <b key={index} className="text-hot">
                                  {num}
                                </b>
                              ) : index == 2 ? (
                                <b key={index} className="text-sale">
                                  {num}
                                </b>
                              ) : (
                                <b key={index} className="text-best">
                                  {num}
                                </b>
                              );
                            })}
                          </span>
                        </div>
                        <div className="login_footer form-group mb-50">
                          <div className="chek-form">
                            <div className="custome-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox"
                                id="exampleCheckbox12"
                                checked={isAgree}
                                onChange={(e) =>
                                  e.target.checked
                                    ? setIsAgree(true)
                                    : setIsAgree(false)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheckbox12"
                              >
                                <span>I agree to terms &amp; Policy.</span>
                              </label>
                            </div>
                          </div>
                          <Link to="/privacy-policy">
                            <i className="fi-rs-book-alt mr-5 text-muted" />
                            Lean more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <div className="text-center">
                      <div className="form-group mb-30">
                        <button
                          type="submit"
                          className="btn btn-fill-out btn-block hover-up font-weight-bold"
                          name="login"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Submit &amp; Register
                        </button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <p className="font-xs text-muted col-lg-8">
                          <strong>Note:</strong>Your personal data will be used
                          to support your experience throughout this website, to
                          manage access to your account, and for other purposes
                          described in our privacy policy
                        </p>
                      </div>
                    </div>
                    <ConfirmationPopupModal
                      title={"Customer Registered Successfully"}
                      content={`Thank you for your registration with Silver Line wholesale,You will be able to login into your account after we send you confirmation on your email`}
                      show={confirmationPopup}
                      isOk={true}
                      setShow={setconfirmationPopup}
                      confirmClicked={(e) => {
                        navigate("/login");
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
