import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { lostPassword } from "../../services/AuthService";
import { randomSecurityCode, randomString } from "../../utils/constant";

const LostPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [securityCode, setSecurityCode] = useState("");
  const [code, setCode] = useState("");
  const [isAgree, setIsAgree] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSecurityCode(randomSecurityCode());
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      let token = randomString(32);
      setIsLoading(true);
      await lostPassword(email, token)
        .then((response) => {
          localStorage.setItem("pass-reset-token", token);
          toast("Email Sent Successfully. Please Check Your Mail Inbox.", {
            type: "success",
          });
        })
        .catch((error) => {
          console.log("Error", error.response);
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const validateInput = () => {
    if (email == "") {
      toast("Please enter username.", {
        type: "error",
      });
      return false;
    }
    if (code == "") {
      toast("Please enter security code.", {
        type: "error",
      });
      return false;
    }
    if (code != securityCode) {
      toast("Security code does not match.", {
        type: "error",
      });
      return false;
    }
    if (!isAgree) {
      toast("Please accept the terms and conditions.", {
        type: "error",
      });
      return false;
    }
    return true;
  };

  return (
    <>
      <main className="main pages">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                <i className="fi-rs-home mr-5" />
                Home
              </Link>
              <span /> Forgot Password
            </div>
          </div>
        </div>
        <div className="page-content pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-12 m-auto">
                <div className="login_wrap widget-taber-content background-white">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <img
                        className="border-radius-15"
                        src="/images/page/forgot_password.svg"
                        alt=""
                      />
                      <h2 className="mb-15 mt-15">Forgot your password?</h2>
                      <p className="mb-30">
                        Not to worry, we got you! Let’s get you a new password.
                        Please enter your email address or your Username.
                      </p>
                    </div>
                    <form>
                      <div className="form-group">
                        <input
                          type="email"
                          required=""
                          name="email"
                          placeholder="Username *"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="login_footer form-group">
                        <div className="chek-form">
                          <input
                            type="text"
                            required=""
                            name="code"
                            placeholder="Security code *"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </div>
                        <span className="security-code">
                          {securityCode.split("").map((num, index) => {
                            return index == 0 ? (
                              <b key={index} className="text-new">
                                {num}
                              </b>
                            ) : index == 1 ? (
                              <b key={index} className="text-hot">
                                {num}
                              </b>
                            ) : index == 2 ? (
                              <b key={index} className="text-sale">
                                {num}
                              </b>
                            ) : (
                              <b key={index} className="text-best">
                                {num}
                              </b>
                            );
                          })}
                        </span>
                      </div>
                      <div className="login_footer form-group mb-50">
                        <div className="chek-form">
                          <div className="custome-checkbox">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="checkbox"
                              id="exampleCheckbox1"
                              defaultValue=""
                              checked={isAgree}
                              onChange={(e) =>
                                e.target.checked
                                  ? setIsAgree(true)
                                  : setIsAgree(false)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheckbox1"
                            >
                              <span>I agree to terms &amp; Policy.</span>
                            </label>
                          </div>
                        </div>
                        <Link className="text-muted" to="/privacy-policy">
                          Learn more
                        </Link>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          disabled={isLoading}
                          className="btn btn-heading btn-block hover-up"
                          name="login"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Reset password
                          {isLoading ? (
                            <img
                              style={{ marginLeft: "10px" }}
                              src="/images/loader.gif"
                              width="25"
                              alt="loader"
                            />
                          ) : null}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LostPassword;
