import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveOrders } from "../../services/OrderService";
import { stateByIds } from "../../services/StateService";
import {
  convertToSlug,
  decodeHTMLEntities,
  selectedPaymentType,
} from "../../utils/constant";
import Navigation from "./Navigation";
import { getAddressDetailsAPI } from "../../services/CartService";
import { getPosPaymentTypes } from "../../services/CustomerService";
import Loader from "../Loader";

const Orders = () => {
  const [showOrder, setShowOrder] = useState(false);
  const [orders, setOrders] = useState([]);
  const [singleOrder, setSingleOrder] = useState({});
  const [orderStates, setOrderStates] = useState([]);
  const [addressId, setaddressId] = useState(0);
  const [paymentType, setPaymentType] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const navigate = useNavigate();
  let date = "";

  useEffect(() => {
    window.scrollTo(0, 0);
    getAddressDetails();
    getPosPaymentType();
  }, []);

  useEffect(async () => {
    if (singleOrder?.billing_address) {
      let ids = [
        singleOrder.billing_address.state_id,
        singleOrder.shipping_address.state_id,
      ].join(",");
      await stateByIds(ids)
        .then((response) => {
          if (response?.data?.data) setOrderStates(response.data.data);
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error" });
        });
    }
  }, [singleOrder]);

  const getPosPaymentType = async () => {
    await getPosPaymentTypes()
      .then((response) => {
        setPaymentType(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const getAddressDetails = () => {
    getAddressDetailsAPI()
      .then((response) => {
        setaddressId(response?.data?.data[0]?.state_id);
        getOrders(response?.data?.data[0]?.state_id);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getOrders = async (stateId) => {
    let id = stateId || addressId;
    await retriveOrders(id)
      .then((response) => {
        if (response?.data?.data) {
          getTotalTax(response.data.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status === 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const getTotalTax = (data) => {
    let mainArray = [];
    for (let a of data) {
      let mainObj = a;
      let finalPrice = 0;
      let salesPrice = 0;
      let totalTax = 0;
      let subTotal = 0;
      for (let b of a?.line_items) {
        let taxOfLineItems = b?.tax || 0;

        // finalPrice = finalPrice + b?.item?.detail?.final_price * b?.quantity;
        // salesPrice = salesPrice + b?.item?.detail?.sales_price * b?.quantity;
        salesPrice = salesPrice + (b?.price - taxOfLineItems) * b?.quantity;
        finalPrice = subTotal + b?.subtotal;

        subTotal = subTotal + (b?.price - taxOfLineItems) * b?.quantity;
        if (b?.tax) {
          totalTax = totalTax + b?.tax * b?.quantity;
        }
      }
      mainArray.push({
        ...mainObj,
        finalPrice: finalPrice.toFixed(2),
        salesPrice: salesPrice.toFixed(2),
        subTotal: subTotal.toFixed(2),
        totalTax: totalTax.toFixed(2),
      });
    }
    setOrders(mainArray);
    setisLoading(false);

    // let obj = [tax, salesPrice, finalPrice];
    // return 0;
  };

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Orders
          </div>
        </div>
      </div>

      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <Navigation />
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content">
                    <div
                      className="tab-pane fade active show"
                      id="orders"
                      role="tabpanel"
                      aria-labelledby="orders-tab"
                    >
                      {!isLoading ? (
                        <>
                          {!showOrder ? (
                            <div className="card">
                              <div className="card-header">
                                <h3 className="mb-0">Your Orders</h3>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead className="dashboard-order">
                                      <tr>
                                        <th>Order</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Fulfillment Status</th>
                                        <th>Sub Total</th>
                                        <th>Tax</th>
                                        <th>Grand Total</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {orders.map((order, index) => {
                                        // let values = getTotalTax(order);
                                        return (
                                          <tr
                                            key={index}
                                            className="dashboard-order-list"
                                          >
                                            <td>#order-{order.id}</td>
                                            <td>
                                              {(() => {
                                                date = new Date(
                                                  order.created_at
                                                );
                                                if (
                                                  date != null &&
                                                  date != "Invalid Date"
                                                ) {
                                                  return (
                                                    date.toLocaleString(
                                                      "default",
                                                      {
                                                        month: "long",
                                                      }
                                                    ) +
                                                    " " +
                                                    date.getDate() +
                                                    ", " +
                                                    date.getFullYear()
                                                  );
                                                }
                                              })()}
                                            </td>
                                            <td>{order.order_status}</td>
                                            <td>
                                              {order.fulfillment_status == null
                                                ? "Not Fulfilled"
                                                : order.fulfillment_status ==
                                                  "partial"
                                                ? order.fulfillment_status
                                                : order.fulfillment_status}
                                            </td>
                                            <td>${order?.salesPrice}</td>
                                            <td>${order?.totalTax}</td>

                                            <td>${order?.finalPrice}</td>
                                            <td>
                                              <a
                                                href=""
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setSingleOrder(order);
                                                  setShowOrder(true);
                                                }}
                                                className="btn-small d-block"
                                              >
                                                View
                                              </a>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h2 className="woocommerce-order-details__title">
                                  Order details
                                </h2>
                                <div
                                  style={{
                                    display: "flex",
                                    padding: "4px",
                                    // justifyContent: "end",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm"
                                    onClick={() => {
                                      navigate(
                                        `/my-account/orders/${singleOrder?.id}/${addressId}`
                                      );
                                    }}
                                  >
                                    {" "}
                                    View
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => {
                                      setSingleOrder([]);
                                      setShowOrder(false);
                                    }}
                                  >
                                    {" "}
                                    Go Back
                                  </button>
                                </div>
                              </div>

                              <section className="woocommerce-order-details">
                                <div
                                  style={{
                                    // display: "flex",
                                    // justifyContent: "space-between",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <p style={{ paddingTop: "10px" }}>
                                    Order #
                                    <mark className="order-number">
                                      order-{singleOrder.id}
                                    </mark>{" "}
                                    was placed on{" "}
                                    <mark className="order-date">
                                      {
                                        (date =
                                          new Date(
                                            singleOrder.created_at
                                          ).toLocaleString("default", {
                                            month: "long",
                                          }) +
                                          " " +
                                          new Date(
                                            singleOrder.created_at
                                          ).getDate() +
                                          " " +
                                          new Date(
                                            singleOrder.created_at
                                          ).getFullYear())
                                      }
                                    </mark>{" "}
                                    and currently status is{" "}
                                    <mark className="order-status">
                                      {singleOrder.order_status}
                                    </mark>
                                    .
                                  </p>
                                </div>

                                <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">
                                  <thead>
                                    <tr>
                                      <th className="woocommerce-table__product-name product-name">
                                        Product
                                      </th>
                                      <th className="woocommerce-table__product-name product-name">
                                        quantity
                                      </th>
                                      <th className="woocommerce-table__product-name product-name">
                                        Status
                                      </th>
                                      <th className="woocommerce-table__product-table product-total">
                                        Total
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {singleOrder.line_items?.map(
                                      (item, index) => {
                                        let subtotalByItem =
                                          item?.quantity *
                                          item?.item?.detail?.sales_price;
                                        return (
                                          <tr
                                            key={index}
                                            className="woocommerce-table__line-item order_item"
                                          >
                                            <td className="woocommerce-table__product-name product-name">
                                              <Link
                                                to={
                                                  "/product/" +
                                                  item.item_id +
                                                  "/" +
                                                  convertToSlug(
                                                    item.item.item_name
                                                  ) +
                                                  "/" +
                                                  "true"
                                                }
                                              >
                                                {decodeHTMLEntities(
                                                  item.item?.item_name
                                                )}
                                              </Link>{" "}
                                              {/* <strong className="product-quantity">
                                                ×&nbsp;{item.quantity}
                                              </strong>{" "} */}
                                            </td>
                                            <td className="woocommerce-table__product-name product-name">
                                              <strong className="product-quantity">
                                                &nbsp;{item.quantity}
                                              </strong>{" "}
                                            </td>
                                            <td className="woocommerce-table__product-name product-name">
                                              {item.fulfillment_status == null
                                                ? "Pending"
                                                : item.fulfillment_status}
                                            </td>
                                            <td className="woocommerce-table__product-total product-total">
                                              <span className="woocommerce-Price-amount amount">
                                                <bdi>
                                                  <span className="woocommerce-Price-currencySymbol">
                                                    $
                                                  </span>
                                                  {subtotalByItem.toFixed(2)}
                                                </bdi>
                                              </span>{" "}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th scope="row" colSpan={3}>
                                        Subtotal:
                                      </th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {singleOrder?.subTotal}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" colSpan={3}>
                                        Tax:
                                      </th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {singleOrder?.totalTax}
                                        </span>
                                      </td>
                                    </tr>
                                    {/* <tr>
                                      <th scope="row">Shipping:</th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">$</span>5.00
                                        </span>
                                        &nbsp;<small className="shipped_via">via Flat rate</small>
                                      </td>
                                    </tr> */}
                                    <tr>
                                      <th scope="row" colSpan={3}>
                                        Payment method:
                                      </th>
                                      <td>
                                        {selectedPaymentType(
                                          paymentType,
                                          singleOrder?.payment_type
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" colSpan={3}>
                                        Grand Total:
                                      </th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {singleOrder?.finalPrice}
                                        </span>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </section>
                              <section className="woocommerce-customer-details">
                                <section className="row">
                                  <div className="col">
                                    <h5 className="woocommerce-column__title">
                                      Billing address
                                    </h5>
                                    <address>
                                      {singleOrder.billing_address.name}
                                      <br />
                                      {singleOrder.billing_address.company_name}
                                      <br />
                                      {singleOrder.billing_address.address}
                                      <br />
                                      {singleOrder.billing_address.city}
                                      <br />
                                      {
                                        orderStates[
                                          singleOrder.billing_address.state_id
                                        ]
                                      }
                                      <br />
                                      {singleOrder.billing_address.pincode}

                                      <p className="woocommerce-customer-details--phone">
                                        {singleOrder.phone}
                                      </p>
                                      <p className="woocommerce-customer-details--email">
                                        {singleOrder.email}
                                      </p>
                                    </address>
                                  </div>
                                  {/* /.col-1 */}
                                  <div className="col">
                                    <h5 className="woocommerce-column__title">
                                      Shipping address
                                    </h5>
                                    <address>
                                      {
                                        singleOrder.shipping_address
                                          .customer_name
                                      }
                                      <br />
                                      {
                                        singleOrder.shipping_address
                                          .company_name
                                      }
                                      <br />
                                      {singleOrder.shipping_address.address}
                                      <br />
                                      {singleOrder.shipping_address.city}
                                      <br />
                                      {
                                        orderStates[
                                          singleOrder.shipping_address.state_id
                                        ]
                                      }
                                      <br />
                                      {singleOrder.shipping_address.pincode}
                                      <br />
                                      {singleOrder.shipping_address?.mobile}
                                    </address>
                                  </div>
                                  {/* /.col-2 */}
                                </section>
                                {/* /.col2-set */}
                              </section>
                            </>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Orders;
