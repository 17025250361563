import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveNotifications = async(see_all) => {
    let token =await localStorage.getItem('token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/notifications?see_all=${see_all ? 1 : 0}`,{ headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                handleAuthFailure(error,reject)
            })
    });
}

const seenNotification = async(id) => {
    let token =await localStorage.getItem('token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/seen-notification/${id}`,{ headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                handleAuthFailure(error,reject)
            })
    });
}

const countNotification = async() => {
    let token =await localStorage.getItem('token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/notifications/count`,{ headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                handleAuthFailure(error,reject)
            })
    });
}

function handleAuthFailure(error, reject){
    if(error?.response.status === 401 && error?.response.data.message === "Unauthenticated."){
        localStorage.removeItem('token');
        localStorage.removeItem('is_authenticated');
        
        if(window.location.pathname != '/login'){
            window.location.href = '/login'
        }
    }else{
        reject(error)
    }
}

export { retriveNotifications, seenNotification, countNotification };