import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../services/AuthService";
import { AppContext } from "../../utils/AppProvider";
import { randomSecurityCode } from "../../utils/constant";

const Login = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [passwordType, setpasswordType] = useState(false);
  const [loginAttempt, setloginAttempt] = useState(0);
  const [state, setState] = useState({
    username: "",
    password: "",
    code: "",
  });
  const { setIsAuthenticated } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (loginAttempt === 0) {
      window.scrollTo(0, 0);
    }
    setSecurityCode(randomSecurityCode());
  }, [loginAttempt]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      await login(state)
        .then((response) => {
          localStorage.setItem("is_authenticated", 1);
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem(
            "adjustment_balance",
            response?.data?.data?.adjustment_balance
          );
          localStorage.setItem("userId", response?.data?.data?.userId);
          localStorage.setItem(
            "opening_balance",
            response?.data?.data?.opening_balance
          );

          setIsAuthenticated(1);

          toast("Customer Login Successfully", { type: "success" });

          navigate("/");
        })
        .catch((error) => {
          console.log("Error", error.response);
          setloginAttempt(loginAttempt + 1);
          if (error?.response?.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    if (state.username == "") {
      toast("Please enter username.", {
        type: "error",
      });
      return false;
    }
    if (state.password == "") {
      toast("Please enter password.", {
        type: "error",
      });
      return false;
    }

    if (state.code == "") {
      toast("Please enter security code.", {
        type: "error",
      });
      return false;
    } else if (state.code != securityCode) {
      toast("Security code does not match.", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </a>
            <span /> Login
          </div>
        </div>
      </div>
      <div className="page-content login-page pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="row">
                <div className="col-lg-6 pr-30 d-none d-lg-block">
                  {/* <img
                                        className="border-radius-15"
                                        src="assets/imgs/page/login-1.png"
                                        alt=""
                                    /> */}
                  <video
                    width="100%"
                    loading="lazy"
                    muted="muted"
                    src="/images/login-illustartion.mp4"
                    type="video/mp4"
                    autoPlay="autoplay"
                    loop="loop"
                  ></video>
                </div>
                <div className="col-lg-6 col-md-8">
                  <div className="login_wrap widget-taber-content background-white">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h1 className="mb-5">Login</h1>
                        <p className="mb-30 login-hover">
                          Don't have an account?{" "}
                          <Link to="/register">Create here</Link>
                        </p>
                      </div>
                      <form method="post">
                        <div className="form-group">
                          <input
                            type="text"
                            required=""
                            name="username"
                            placeholder="Username *"
                            value={state.username}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            required=""
                            type={passwordType ? "text" : "password"}
                            name="password"
                            placeholder="Your password *"
                            value={state.password}
                            autoComplete="true"
                            onChange={(e) => handleInputChange(e)}
                          />
                          <img
                            onClick={() => setpasswordType(!passwordType)}
                            src={
                              "/images/theme/icons/" +
                              (passwordType
                                ? "icon-eye.svg"
                                : "icon-eye-off.svg")
                            }
                            className="show-password"
                          />
                        </div>
                        <div className="login_footer form-group">
                          <div className="chek-form">
                            <input
                              type="text"
                              required=""
                              name="code"
                              placeholder="Security code *"
                              value={state.code}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>
                          <span className="security-code">
                            {securityCode.split("").map((num, index) => {
                              return index == 0 ? (
                                <b key={index} className="text-new">
                                  {num}
                                </b>
                              ) : index == 1 ? (
                                <b key={index} className="text-hot">
                                  {num}
                                </b>
                              ) : index == 2 ? (
                                <b key={index} className="text-sale">
                                  {num}
                                </b>
                              ) : (
                                <b key={index} className="text-best">
                                  {num}
                                </b>
                              );
                            })}
                          </span>
                        </div>
                        <div className="login_footer form-group mb-50">
                          {/* <div className="chek-form">
                                                        <div className="custome-checkbox">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="checkbox"
                                                                id="exampleCheckbox1"
                                                                defaultValue=""
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="exampleCheckbox1"
                                                            >
                                                                <span>Remember me</span>
                                                            </label>
                                                        </div>
                                                    </div> */}
                          <Link
                            className="text-muted"
                            to="/my-account/lost-password"
                          >
                            Forgot password?
                          </Link>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-heading btn-block hover-up"
                            name="login"
                            onClick={(e) => handleSubmit(e)}
                          >
                            Log in
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
