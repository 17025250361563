import "./App.css";
import "./assets/css/sass/main.scss";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import "./assets/css/b2b-custom.css";
import "./assets/css/plugins/slider-range.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import Index from "./layout/Index";
import { useEffect, useState } from "react";
import PopupModal from "./components/PopupModal";

function App() {
  let approvelOfAdult = localStorage.getItem("olderThanTwentyOne");

  const [yesClickedToggle, setyesClickedToggle] = useState(approvelOfAdult);

  useEffect(() => {
    // const handleContextmenu = (e) => {
    //   e.preventDefault();
    // };
    // document.addEventListener("contextmenu", handleContextmenu);
    // return function cleanup() {
    //   document.removeEventListener("contextmenu", handleContextmenu);
    // };
  }, []);

  const yesClicked = () => {
    setyesClickedToggle(true);
    localStorage.setItem("olderThanTwentyOne", true);
  };

  return (
    <Router>
      <ToastContainer pauseOnFocusLoss={false} autoClose={500} />
      {!yesClickedToggle && <PopupModal yesClicked={yesClicked} />}
      <Index />
    </Router>
  );
}

export default App;
